<template>
  <v-container fluid>
    <page-title title="Записки арт-терапевта блог Надежды Навроцкой" />
    <list-component :list="articles" @createPath="createPath" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Blog',
  metaInfo: {
    title: 'Записки арт-терапевта | Блог Надежды Навроцкой',
    titleTemplate: null
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    ...mapState({
      articles: state => state.shared.articles,
      loading: state => state.shared.loading
    })
  },
  methods: {
    createPath(value) {
      this.$router.push(`/blog/${value}`)
    }
  }
}
</script>

<style scoped lang="stylus" />
